.button--sacnite::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: -1;
  box-shadow: inset 0 0 0 35px #333;
  -webkit-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1);
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3;
  transition: box-shadow 0.3s, transform 0.3s;
}

.button {
  float: left;
  min-width: 150px;
  max-width: 250px;
  display: block;
  margin: 1em;
  padding: 1em 2em;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.button--round-l {
  border-radius: 40px;
}

.button {
  border-radius: 0;
  float: none;
  margin: 0 auto;
  margin-top: 0px;
  padding: 5px 20px;
  display: inline-block;
  font-weight: 400;
  max-width: 400px;
}

.button.button--sacnite {
  width: 60px;
  height: 60px;
  min-width: 0;
  padding: 0;
  color: #fff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.button--sacnite:hover {
  color: #333;
}

.button--sacnite:hover::before {
  box-shadow: inset 0 0 0 2px #333;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.bmc-button img {
  width: 27px !important;
  margin-bottom: 1px !important;
  box-shadow: none !important;
  border: none !important;
  vertical-align: middle !important;
}

.bmc-button {
  line-height: 36px !important;
  height: 37px !important;
  text-decoration: none !important;
  display: inline-flex ! important;
  color: #ffffff !important;
  background-color: #000000 !important;
  border-radius: 3px !important;
  border: 1px solid transparent !important;
  padding: 1px 9px !important;
  font-size: 22px !important;
  letter-spacing: 0.6px !important;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  margin: 0 auto !important;
  font-family: 'Cookie', cursive !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -o-transition: 0.3s all linear ! important;
  -webkit-transition: 0.3s all linear !important;
  -moz-transition: 0.3s all linear !important;
  -ms-transition: 0.3s all linear !important;
  transition: 0.3s all linear !important;
}

.bmc-button:hover,
.bmc-button:active,
.bmc-button:focus {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  text-decoration: none !important;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  opacity: 0.85 !important;
  color: #ffffff !important;
}
