@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,700,500,500italic,400italic,300italic,100italic,700italic);
@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css);
@import url(//netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.css);

button, input[type="button"], input[type="reset"], input[type="submit"] {
  appearance: none;
  background-color: #477dca;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover,
  button:focus, input[type="button"]:hover,
  input[type="button"]:focus, input[type="reset"]:hover,
  input[type="reset"]:focus, input[type="submit"]:hover,
  input[type="submit"]:focus {
    background-color: #3964a2;
    color: #fff; }
  button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, input[type="button"]:disabled:hover, input[type="reset"]:disabled:hover, input[type="submit"]:disabled:hover {
      background-color: #477dca; }

fieldset {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  margin: 0 0 0.75em;
  padding: 1.5em; }

input,
label,
select {
  display: block;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em; }

label {
  font-weight: 600;
  margin-bottom: 0.375em; }
  label.required::after {
    content: "*"; }
  label abbr {
    display: none; }

select {
  margin-bottom: 1.5em;
  max-width: 100%;
  width: auto; }

dl {
  margin-bottom: 0.75em; }
  dl dt {
    font-weight: bold;
    margin-top: 0.75em; }
  dl dd {
    margin: 0; }

table {
  border-collapse: collapse;
  font-feature-settings: "kern", "liga", "tnum";
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #a6a6a6;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

html {
  box-sizing: border-box; }

*,
*::after,
*::before {
  box-sizing: inherit; }

body {
  background: #ffffff;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased; }

.center-text {
  text-align: center; }

.container {
  margin-top:30px;

  @media print {
    margin-top: 20px;
  }
}

.header-container {
  margin-top:50px;
}

.header-right {
  margin-top:20px;
  text-align: right;
}

.header-left {
  margin-top: 20px;

  @media print {
    margin-top: 0px;
  }
}

.header-right p {
  font-size: 1.6em;
  text-align: right;
}

@media print {
  a[href]:after {
    content: none !important;
  }

  img[src]:after {
    content: none !important;
  }

  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width:8.33333333%;
  }
  .col-print-12 {
    width: 100%;
  }

  .no-print, .no-print * {
    display: none !important;
  }
}
