@import "modern-resume-theme/base";
@import "modern-resume-theme/button";

ul.icons a:hover {
  background: rgba(0, 0, 0, 0.025);
}

ul.icons li {
  display: inline-block;
  padding-left: 0.75em;
}

ul.icons a {
  -moz-transition: background-color 0.25s ease-in-out;
  -webkit-transition: background-color 0.25s ease-in-out;
  -ms-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
  display: inline-block;
  width: 2.75em;
  height: 2.75em;
  line-height: 2.8em;
  text-align: center;
  border: 0;
  box-shadow: none;
  padding:0;
  border-radius: 100%;
  color: #aaa;
}

.icons i {
  font-size: 30px;
  line-height:60px;
}

.icons {
  padding: 0;
  font-size: 1em;
  margin-bottom:20px;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .icons {
    text-align: center;
  }
}

.header-right p {
  margin-bottom: 3px;
}

.header-right a{
  color:inherit;
  font-weight:500;
}

@media screen and (max-width: 768px) {
  .header-container div{
    text-align: center;
  }

  .header-right p {
    text-align: center;
  }

  .header-left {
    margin-top: 0px;
  }
}

.profile-img {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  margin-bottom: 20px;
  display: block;
  border-radius: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: repeat, no-repeat;
  -moz-box-shadow: inset 0 0 20px #636161;
  -webkit-box-shadow: inset 0 0 20px #636161;
  box-shadow: inset 0 0 20px #636161;
}

.profile-img-small {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  margin-bottom: 8px;
  display: block;
  border-radius: 24px;
  background-size: cover;
  background-position: center;
  background-repeat: repeat, no-repeat;
  -moz-box-shadow: inset 0 0 20px #636161;
  -webkit-box-shadow: inset 0 0 20px #636161;
  box-shadow: inset 0 0 20px #636161;
}

.into-text-1 {
  margin-top: 30px;
}

@media only screen and (max-width : 992px) {
  .into-text-1 {
    margin-top: 20px;
  }
}

.projects-container, .experience-container, .education-container {
  h3 {
    margin-bottom: 0;
  }
}

.experience, .education, .project {
  margin-top: 3em;

  .left-column {
    text-align: left;

    p {
      margin-bottom: 3px;
      text-align: inherit;
      font-weight: 300;

      b {
        font-weight: 500;
      }
    }

    @media screen and (max-width: 768px) {
      text-align: center;

      p {
        text-align: center;
      }
    }

    a {
      color: inherit;
      font-style: italic;
      font-weight: 300;
    }

    .fa {
      font-size: 2em;
    }

    .project-link {
      font-size: 1.5em;
    }

    h4 {
      margin-bottom: 0.4em;
      font-weight: 500;

      @media print {
        margin-bottom: 0.2em;
      }

      a {
        font-weight: 500;
      }
    }
  }
}

.footer-container {
  margin-top:20px;
  border-top: 1px solid $border-color;
}

.footer-container p{
  text-align: center;
  font-style: italic;
  font-weight: 300;
}

.footer-container a{
  color: inherit;
  font-style: italic;
  font-weight: 300;
}

.page-content {
  padding: 0;
}

.contact a {
  line-height: 50px;
  margin-left: 30px;
  color: #BBBBBB;
  -webkit-transition: color 0.4s ease;
  -moz-transition: color 0.4s ease;
  transition: color 0.4s ease;
}

.contact a:hover {
  color: #4C4C4C;
}

p.quote {
  font-style: italic;
  padding: 1em 2.5em;
  text-align: center;

  @media print {
    text-align: left;
    padding: 0em 1em;
    margin-top: 1em;
  }
}
